<template>
  <div class="col-sm-12 col-xl-6 col-xxl-4 mb-3 min-width-home">
    <div id="userInfo" v-bind:class="darkMode" class="card p-3">
      <div v-if="user == null">
        <content-loader :width="330" :height="84" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
          <rect x="100" y="1" rx="3" ry="3" width="150" height="7" />
          <rect x="100" y="11" rx="3" ry="3" width="180" height="7" />

          <rect x="100" y="49" rx="6" ry="6" width="230" height="35" />
          <rect x="0" y="0" rx="9" ry="9" width="84" height="84" />
        </content-loader>
      </div>
      <div v-else>
        <div class="row">
          <div class="col">
            <!-- Profile Picture -->
            <avatar-view :name="user.fname + ' ' + user.lname"></avatar-view>
            <!-- End Profile Picture -->
          </div>
          <div class="col-8 col-sm-9 col-md-10 col-lg-9 d-flex flex-column justify-content-between" style="padding-left: 0px">
            <div class="row">
              <div class="row">
                <div class="col">
                  <h4 class="no-overflow">{{user.fname}} {{user.lname}}</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-end">
                <router-link v-bind:class="darkModeBtn" class="btn col col-sm-12 col-md-4 col-lg-4 col-xl-6" to="/account">Edit Profile</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { darkModeStyle } from '../../assets/js/darkMode';

export default {
  name: 'UserInfo',
  components: {
    ContentLoader,
  },
  data() {
    return {
      user: null,
      darkMode: darkModeStyle(),
      darkModeBtn: darkModeStyle()
    }
  },
  computed: {
    userData: function() {
      if (typeof this.$store.getters.user != 'undefined') {
        this.user = this.$store.getters.user;
      }
    }
  },
  watch: {
    '$store.getters.user': function(newVal) {
      if (newVal != null) {
        this.user = newVal;
      }
    }
  },
  mounted() {
    this.userData;
  }
}
</script>